"use strict";
import "./bootstrap";
import "livewire-sortable";
import tippy, { followCursor } from "tippy.js";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";
import colorLib from "@kurkle/color";
import mask from "@alpinejs/mask";
import focus from "@alpinejs/focus";
import { collapse } from "@alpinejs/collapse";

window.tippy = tippy;
window.followCursor = followCursor;

document.addEventListener("alpine:init", () => {
	Alpine.plugin(mask, focus, collapse);
	Alpine.data("colorLib", colorLib);
	// Magic: $tooltip
	Alpine.magic("tooltip", (el) => (message) => {
		let instance = tippy(el, { content: message, trigger: "manual" });
		instance.show();
		setTimeout(() => {
			instance.hide();
			setTimeout(() => instance.destroy(), 150);
		}, 2000);
	});

	// Directive: x-tooltip
	Alpine.directive("tooltip", (el, { expression, modifiers }) => {
		tippy(el, {
			content: expression,
			duration: [300, 400],
			delay: [50, 200],
			allowHTML: true,
			placement: modifiers[0] ?? "top",
			onShow(instance) {
				const prefersDarkScheme = window.matchMedia(
					"(prefers-color-scheme: dark)",
				).matches;
				const darkMode = localStorage.getItem("raid_theme") === "dark";

				instance.setProps({
					theme:
						prefersDarkScheme || darkMode ? "light" : "translucent",
				});
			},
			followCursor: "horizontal",
			plugins: [followCursor],
		});
	});
});
